<template>
  <div class="newsinfo">
    <div class="newsinfocenter">
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/newstrend' }">新闻动态</el-breadcrumb-item>
          <el-breadcrumb-item>{{showtext}}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <main>
        <!-- 新闻 -->
        <gone v-if="showid==1"></gone>
        <gtwo v-if="showid==2"></gtwo>
        <gthree v-if="showid==3"></gthree>
        <gfour v-if="showid==4"></gfour>
        <gfive v-if="showid==5"></gfive>
      </main>
    </div>
  </div>
</template>
<script>
import gone from "./compontents/one.vue";
import gtwo from "./compontents/two.vue";
import gthree from "./compontents/three.vue";
import gfour from "./compontents/four.vue";
import gfive from "./compontents/five.vue";
export default {
  components: {
    gone,
    gtwo,
    gthree,
    gfour,
    gfive,
  },
  data() {
    return {
      showid: null,
      showtext: null,
    };
  },
  created() {
    console.log(this.$route.query, "路由");
    this.showid = this.$route.query.id;
    this.showtext = this.$route.query.text;
  },
};
</script>
<style lang="scss" scoped>
.newsinfo {
  .newsinfocenter {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
  }
}
</style>